const account = {
   myAccount: 'Mon compte',
   myProfile: "Mon profil",
   shippingAddress: "Adresse",
   bankAccount: "Compte de paiement",
   myEarnings: "Mes revenus",
   earningHistory: "Record de gains",
   withdraw: "Retrait de gains",

   logout: "Déconnexion",

   personalInfo: "Informations personnelles",
   email: "Adresse e-mail",
   invitationCode: "Code d'invitation",
   name:"Nom",
   gender: "Sexe",
   language:"Langue",
   password:"Le mot de passe",
   confirmPassword:"Confirmer le mot de passe",
   changePassword:"Changer le mot de passe",
   male:"Homme",
   female:"Femme",
   other:"Je préfère ne pas répondre",

   socialProfiles: "Profils sociaux",
   follower:"Région concentrant le plus d'abonnés",
   fbName:"Identifiant Facebook",
   insName:"Instagram Handle",
   tiktokName:"TikTok Handle",

   save:"Enregistrer",
   new:"Nouveau",
   nil:"Nul",
   edit:"Éditer",
   

}

export default account