var host = 'http://any.clubchopp.com'

// if (process.env.NODE_ENV === 'production') {
//     host = 'https://www.anystarr.com'
// }


if (window.location.href.indexOf('anystarr') != -1) {
    host = 'https://api.anystarr.com'
}

module.exports = host