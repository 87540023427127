const task = {
    searchInput: 'Rechercher',
    applicationDate: "Date d'application",
    taskState0: 'En attente du code promo ',
    taskState1: "Nouvelle mission",
    taskState2: 'En attente de preuves',
    taskState3: 'En attente de vérification',
    taskState4: "En attente d'autres preuves",
    taskState5: "Elements preuves confirmés",
    all: "Toutes mes tâches",
    status:"Etat de la mission",

    activated:"Activated",
    submitted:"Submitted",
    verified:"Verified",


}

export default task

