const campaignsDetail = {
    campaignId: 'Campaign ID',
    campaignType: 'Campaign Type',
    campaignDuration: 'Duration',
    freeSample: 'Free Sample',
    yes:'Yes',
    no:'No',
    averageEarn: 'Average earnings',
    like: 'Like',
    applyNow: 'Apply now',
    task: 'Task',
    step: 'Step',
    step1: 'Apply and receive an exclusive promo code.',
    step2: 'Share the campaign on your social media.',
    step3: 'Submit proof screenshots or links to anyStarr.',

}

export default campaignsDetail