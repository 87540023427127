const address = {
    shippingAddress: "Shipping address",
    delete: "Delete",
    edit:"Edit",
    newAddress:"New Address",
    addAddress:"Add address",
    name:"Name",
    contactNum:"Contact number",
    address:"Address",
    country:"Country",
    postCode:"Post code",
    save:"Save",

}

export default address