import zh_CN from './zh_CN'
import en_US from './en_US'
import fr_FR from './fr_FR'
import es_ES from './es_ES'
// import th_TH from './th_TH'

export default {
    zh_CN,
    en_US,
    fr_FR,
    es_ES
    // th_TH
}