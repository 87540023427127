

const taskDetail = {
    statusFur: 'Status further explanation , if needed',
    step1: 'Paso1',
    stepDes1: 'Solicitar y obtener su código promocional exclusivo',
    applyPromoCode: 'Haga clic para solicitar',
    howToUse: 'Cómo usar eficazmente el código promocional',
    copyCode: 'Copiado',
    step2: 'Paso2',
    stepDes2: 'Compartir en red social',
    step3: 'Paso3',
    stepDes3: 'Subir capturas de pantalla como evidencia',
    newImage: 'Nueva imagen',
    inputWebsitelink: 'Subir enlaces compartidos como evidencia',
    addUri: 'Agregar más enlaces',
    submit: 'Subir',

    step1: "Solicitar y obtener su código promocional exclusivo",
    step2: "Compartir en red social",
    step3: "Subir capturas de pantalla como evidencia",

    endDate: "Fecha de terminación",
    discountCode: 'Código promocional',

    clicktoApply: 'Código promocional',
    noCode: 'Más código por venir',

     newImages: "Nueva imagen",

    upPic:"Hasta 5 imágenes, formatos compatibles: JPG, JPEG, PNG (máx. 3MB)",

    upLink: "Hasta 3 enlaces",

    addMoreLink: "Agregar más enlaces",

    submitScreenshot:"Subir capturas de pantalla de evidencia a anyStarr",
    submitLinkL:"Subir capturas de enlaces de evidencia a anyStarr",

    sampleStepDes:"Apply and receive the sample",
    applySample:"Click to apply",

    


}

export default taskDetail