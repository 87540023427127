const task = {
    searchInput: 'Buscar',
    applicationDate: 'Fecha de Solicitud',
    taskState0: 'Esperando código promocional',
    taskState1: 'Nueva tarea',
    taskState2: 'Subida de evidencia',
    taskState3: 'Pendiente de verificación',
    taskState4: 'Subida de evidencia nueva',
    taskState5: 'Evidencia verificada',
    all: "Todas mis tareas",
    status:"estado de la tarea",

    activated:"Activado",
    submitted:"Enviado",
    verified:"Verificado",


}

export default task