const campaignsDetail = {
    campaignId: 'ID de campagne',
    campaignType: 'Type de campagne',
    campaignDuration: 'Durée',
    distributionCountry: 'Région concernée',
    freeSample: 'Echantillon gratuit',
    averageEarn: 'Revenu moyen',
    like: "J'aime",
    applyNow: 'Rejoindre',
    task: 'Mission',
    step: 'Etape',
    step1: 'Etape 1: Faîtes la demande et recevez un code promo exclusif.',
    step2: 'Etape 2: Partagez cela sur vos réseaux sociau.',
    step3: "Etape 3: Envoyez les captures d’écrans ou liens comme élément de preuve à anyStarr.",

}

export default campaignsDetail

