
import { param } from 'jquery'
import host from '../tools/host'
import request from '../tools/request'


//登录
export const APIlogin = params => {
    return request.get(`${host}/any-starr/api/user/login`, params)
}

//登出
export const APIlogout = (params,props) => {
    return request.get(`${host}/any-starr/api/user/logout`, params, props)
}

//注册
export const APIregister = params => {
    return request.post(`${host}/any-starr/api/user/register`, params)
}

//emial login
export  const APIemailLogin = params => {
    return request.post(`${host}/any-starr/api/user/email_login`, params)
}

//获取国家
export const APIgetCountry = params => {
    return request.get(`${host}/any-starr/common/sys/find_countries`, params)
}

//根据邀请码获取邮箱
export const APIgetEmailByInvitecode = params => {
    return request.get(`${host}/any-starr/api/user/get_email`, params)
}

//第三方登录
export const APIfederatedLogin = params => {
    return request.post(`${host}/any-starr/api/user/federated_login`, params)
}

//发送邮箱验证码
export const APIsendVerificationCode = params => {
    return request.post(`${host}/any-starr/api/user/send_verification_code`, params)
}

//第三方登录后续账号绑定
export const APIbindUser = params => {
    return request.post(`${host}/any-starr/api/user/bind_user`, params)
}

//获取AllCampaigns 
export const APIgetAllCampaigns = params => {
    return request.post(`${host}/any-starr/api/campaign/find_all_campaigns`, params)
}

//根据id 获取campaign 详情
export const APIgetCampaignDetail = params => {
    return request.post(`${host}/any-starr/api/campaign/get_campaign_detail`, params)
}

//campaign like or unlike
export const APIlikeCampaign = params => {
    return request.post(`${host}/any-starr/api/campaign/like_campaign`, params)
}

//申领campaign 
export const APIapplyCampaign = params => {
    return request.post(`${host}/any-starr/api/campaign/apply_campaign`, params)
}

//my task list
export const APIfindMyTasks = (params, props) => {
    return request.post(`${host}/any-starr/api/task/find_my_tasks`, params, props)
}

//task 详情
export const APIgetTaskDetail = params => {
    return request.post(`${host}/any-starr/api/task/get_task_detail`, params)
}

//上传文件
export const APIuploadFile = params => {
    return request.upload(`${host}/any-starr/common/upload/upload_file`, params)
}

//提交task
export const APIsubmitTask = params => {
    return request.post(`${host}/any-starr/api/task/submit_task`, params)
}

//申请促销码
export const APIgetPromoCode = params => {
    return request.post(`${host}/any-starr/api/task/get_promo_code`, params)
}

//校验邮箱验证码
export const APIcheckVerificationCode = params => {
    return request.post(`${host}/any-starr/api/user/check_verification_code`, params)
}


//校验邮箱需要判断是否注册过
export const APIcheckVerificationCodeNormal = params => {
    return request.post(`${host}/any-starr/api/user/check_email_and_code`, params)
}


//my profile

export const APIgetMyProfile = (params,props) => {
    return request.post(`${host}/any-starr/api/user/get_my_profile`, params, props)
}

//modify profile

export const APImodifyUserInfo = params => {
    return request.post(`${host}/any-starr/api/user/modify_user_information`, params)
}

//modify password
export const APImodifyPassword = params => {
    return request.post(`${host}/any-starr/api/user/change_my_password`, params)
}

//modify social
export const APImodifySocial = params => {
    return request.post(`${host}/any-starr/api/user/set_user_social`, params)
}

//account balance
export const APIgetAccountBalance = params => {
    return request.post(`${host}/any-starr/api/payment/get_account_balance`, params)
}

//apply withdrawlal
export const APIapplyWithdrawal = params => {
    return request.post(`${host}/any-starr/api/payment/apply_withdrawal`, params)
}


//find my pay account
export const APIgetMyPayAccount = params => {
    return request.post(`${host}/any-starr/api/user/find_my_pay_account`, params)
}

export const APIgetWithdrawHistory = params => {
    return request.post(`${host}/any-starr/api/payment/find_my_payout`, params)
}

export const APIgetToBeReviewed = params => {
    return request.post(`${host}/any-starr/api/payment/find_my_review_payout`, params)
}


//get  bank list
export const APIgetBankList = params => {
    return request.get(`${host}/any-starr/common/sys/find_bank`, params)
}


//create bank account
export const APIcreatePayAccount = params => {
    return request.post(`${host}/any-starr/api/user/create_pay_account`, params)
}

//delete bank account
export const APIdeletePayAccount = params => {
    return request.post(`${host}/any-starr/api/user/remove_my_pay_account`, params)
}

//modify user avatar
export const APImodifyUserAvatar = params => {
    return request.post(`${host}/any-starr/api/user/modify_user_avatar`, params)
}


//send message
export const APIsendMessage = params => {
    return request.post(`${host}/any-starr/api/message/send_message`, params)
}

//anystarr updates list
export const APIgetMyPostList = (params, props) => {
    return request.post(`${host}/any-starr/api/message/find_my_post_task`, params, props)
}


//get post detail
export const APIgetPostTaskDetail = params => {
    return request.post(`${host}/any-starr/api/message/get_post_task_detail`, params)
}

//get post number
export const APIgetPostNum = params => {
    return request.post(`${host}/any-starr/api/message/get_unread_quantity`, params)
}

//get my address
export const APIgetMyAddress = params => {
    return request.post(`${host}/any-starr/api/user/find_my_address`, params)
}

//modify or create address
export const APIcreateOrModifyAddress = params => {
    return request.post(`${host}/any-starr/api/user/create_or_modify_user_address`, params)
}

//delete address
export const APIdeleteAddress = params => {
    return request.post(`${host}/any-starr/api/user/remove_user_address`, params)
}


// code commission list
export const APIgetMyCodeCommission = params => {
    return request.post(`${host}/any-starr/api/payment/find_my_code_commission`, params)

}

// user code task total earning
export const APIgetMyCodeDetail = params => {
    return request.post(`${host}/any-starr/api/payment/find_my_code_detail`, params)
}

//verification password
export const APIverificationPassword = params => {
    return request.post(`${host}/any-starr/api/user/check_original_password`, params)
}


//message list 
export const APIgetMyMessage = params => {
    return request.post(`${host}/any-starr/api/message/find_my_message`, params)
}

//message detail
export const APIgetMessageDetail = params => {
    return request.post(`${host}/any-starr/api/message/get_message_detail`, params)
}

//message close
export const APIcloseTicket = params => {
    return request.post(`${host}/any-starr/api/message/close_this_ticket`, params)
}

//message ask
export const APIaskTicket = params => {
    return request.post(`${host}/any-starr/api/message/ask_again`, params)
}


//register category list
export const APIgetCategory = params => {
    return request.post(`${host}/any-starr/api/category/find_category_show`, params)
}

//apply sample
export const APIApplySample = params => {
    return request.post(`${host}/any-starr/api/task/apply_sample`, params)
}