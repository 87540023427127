const bank = {
    AddAccount:"Ajouter un compte",
    bankPaypal:"Compte Bancaire/PayPal",
    accountType:"Type de compte",
    accountNo:"IBANo",
    fullNameBank:"Titulaire du compte",
    bankName:"Banque",
    swiftCode:"Swift BIC",
    bank:"Banque",
    bankAccount:"Compte bancaire",
    paypalAccount:"Compte PayPal"

}
export default bank