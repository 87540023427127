const login = {
    emailPlacehold: 'Adresse e-mail',
    passwordPlacehold: 'Mot de passe',
    login: 'Se connecter',
    forgetPassword: 'Mot de passe oublié ?',
    notMember: "Vous n'avez pas encore de compte ?",
    clickToRegister: "S'inscrire",
    or: 'ou',
    socialAccount: 'Se connecter avec les comptes de réseaux sociaux',
    pleaseAccount: "L'adresse mail est manquante",
    pleasePassword: 'Mot de passe est manquante',

    resetPassword:"Réinitialisez votre passe",
    resetPasswordDes:"Veuillez saisir et confirmer votre nouveau mot de passe ci-dessous pour accéder à votre compte"
}
export default login

