const login = {
    emailPlacehold: 'Correo electrónico',
    passwordPlacehold: 'Contraseña',
    login: 'Iniciar sesión',
    forgetPassword: 'Olvidé mi contraseña',
    notMember: "¿No tiene una cuenta?",
    clickToRegister: 'Regístrese',
    or: 'o',
    socialAccount: 'Inicie sesión con una cuentas de red social',
    pleaseAccount: 'Falta el correo electrónico',
    pleasePassword: 'Falta la contraseña',

    resetPassword:"Reinicia tu pase",
    resetPasswordDes:"Ingrese y confirme su nueva contraseña a continuación para acceder a su cuenta"
}
export default login