import {
    getCurrentLanguage,
    setCurrentLanguage
} from '../locales/util'
import Cookies from 'js-cookie'

let userInfo =  Cookies.get('dsb_user_info')?JSON.parse(Cookies.get('dsb_user_info')) :null
console.log('reduceruseringo',userInfo)

export const COMMON_CHANGE_LANGUAGE = 'COMMON_CHANGE_LANGUAGE';
export const COMMON_SET_LOADING = 'COMMON_SET_LOADING';
export const COMMON_SET_INFO = 'COMMON_SET_INFO'
export const COMMON_GET_USER_INFO = 'COMMON_GET_USER_INFO'
export const COMMON_SET_TITLE = 'COMMON_SET_TITLE'

export const COMMON_SET_HEADER = 'COMMON_SET_HEADER'
export const COMMON_SET_USER_INFO = 'COMMON_SET_USER_INFO'

export const COMMON_SET_POST_NUM = 'COMMON_SET_POST_NUM'

export const COMMON_SET_MESSAGEUNREAD = 'COMMON_SET_MESSAGEUNREAD'
export const COMMON_SET_MESSAGEACTIVITY = 'COMMON_SET_MESSAGEACTIVITY'

const initialState = {
    language: getCurrentLanguage(),
    header:  userInfo?userInfo.head:'',
    userInfoG: userInfo,
    postNum:1,
    messageActivity:0,
    messageUnread:{
        // activitys:0,
        // promotions:0,
        // updates:0,
    }

}

export default function appReducers(state = initialState, action) {
    switch (action.type) {
        case COMMON_CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.val
            }
        case COMMON_SET_HEADER:
            return {
                ...state,
                header: action.val
            }    

        case COMMON_SET_USER_INFO:
            return {
                ...state,
                userInfoG: action.val
            }   
        case COMMON_SET_POST_NUM:
            return {
                ...state,
                postNum: action.val
            }
        case COMMON_SET_MESSAGEUNREAD:
            return {
                ...state,
                messageUnread: action.val
            }   
        case COMMON_SET_MESSAGEACTIVITY:
            return {
                ...state,
                messageActivity: action.val
            }          
            default:
                return state
    }
}

export const changePostNum = (val) => {
    return dispatch => {
        dispatch({
            type: COMMON_SET_POST_NUM,
            val: val
        })
    }
}

export const changeLanguage = (val) => {
    return dispatch => {
        dispatch({
            type: COMMON_CHANGE_LANGUAGE,
            val: val
        })
    }
}

export const changeHeader = (val) => {
    return dispatch => {
        dispatch({
            type: COMMON_SET_HEADER,
            val: val
        })
    }
}

export const changeUserInfo = (val) => {
    return dispatch => {
      dispatch({
        type: COMMON_SET_USER_INFO,
        val: val
      })
    }
}

export const changeMessageUnread = (val) => {
    console.log('changeMessageUnread   hhh',val)
    return dispatch => {
        dispatch({
            type: COMMON_SET_MESSAGEUNREAD,
            val: val
        })
    }
}

export const changeMessageActivity = (val) => {
    return dispatch => {
        dispatch({
            type: COMMON_SET_MESSAGEACTIVITY,
            val: val
        })
    }
}