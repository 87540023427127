const bank = {
    AddAccount:"Add account",
    bankPaypal:"Bank/Paypal account",
    accountType:"Account Type",
    accountNo:"Account No",
    fullNameBank:"Full name in your bank account",
    bankName:"Bank name",
    swiftCode:"swift code",
    bank:"Bank",
    bankAccount:"Bank Account",
    paypalAccount:"PayPal Account",
    acountNo:"Account No",

}
export default bank