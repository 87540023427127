const campaignsDetail = {
    campaignId: 'ID de Campaña',
    campaignType: 'Tipo de Campaña',
    campaignDuration: 'Duración',
    distributionCountry: 'Cobertura de región(es)',
    freeSample: 'Muestra Gratuita',
    averageEarn: 'Ganancia media',
    like: 'Favorito',
    applyNow: 'Únase Ahora',
    task: 'Tarea',
    step: 'Paso',
    step1: 'Paso1: Solicitar y obtener un código promocional exclusivo.',
    step2: 'Paso2: Compartirlo en su red social.',
    step3: 'Paso3: Subir capturas de pantalla o enlaces de evidencia a anyStarr.',

}

export default campaignsDetail