const common =  {
    login: "Se connecter/S'inscrire",
    loginS: "Se connecter",
    signUp: "S'inscrire",
    contactUs: 'Nous contacter',
    allCampaigns: 'Les campagnes',
    myTasks: 'Mes missions',
    messages: 'Mes messages',
    search: 'Rechercher',
    allRegion: 'Toutes les régions',
    france: 'France',
    spain: 'Espagne',
    rejectReason:'Raison du rejet',

    next:"Suivant",
    forgetPwd:"Mot de passe oublié",
    pleasePwd:"Entrez le mot de pass actuel",
    pleaseEmail:"Saisissez l'adresse e-mail",
    invalidLink:"Un lien invalide",
}

export default common

