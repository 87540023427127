const common =  {
    login: 'Iniciar sesión/Regístrese',
    loginS: "Iniciar sesión",
    signUp: "Regístrese",
    contactUs: 'Contáctenos',
    allCampaigns: 'Campañas',
    myTasks: 'Mis tareas',
    messages: 'Mensaje',
    search: 'Buscar',
    allRegion: 'Todas las regiones',
    france: 'Francia',
    spain: 'España',
    rejectReason:'Motivo del rechazo',

    next:"Siguiente",
    forgetPwd:"Olvidé mi contraseña",
    pleasePwd:"Ingrese la contraseña actual",
    pleaseEmail:"Introduzca la dirección de correo electrónico",
    invalidLink:"Un enlace inválido",
}

export default common
