const common =  {
    login: ' Login/Sign Up',
    loginS: "Login",
    signUp: "Sign Up",
    contactUs: 'Contact us',
    allCampaigns: 'All campaigns',
    myTasks: 'My tasks',
    messages: 'Messages',
    search: 'Search',
    allRegion: 'All regions',
    france: 'France',
    spain: 'Spain',
    rejectReason:'Reason for rejection',

    next:"next",
    forgetPwd:"Forget Password",
    pleasePwd:"Please enter your current password",
    pleaseEmail:"Please enter your Email",
    invalidLink:"Invalid link",

}

export default common
