const earn = {
    codesClaimed:"Codes Claimed",
    onGoingTasks:"Ongoing tasks",
    totalEarning:"Total earning",
    earnDes:"The earnings are disclosed in the middle of each month and provided solely at the discretion of this campaign's brand.",
    duration:"Duration",
    promoCode:"Promo code",
    earnUpToDate:"Earning up to date",
    updatedOn:"Updated on",
    AccountBalance:"Account balance",
    withdraw:"Withdraw",
    lasestWithdrawRequest:"Latest withdraw request",
    toBankAccount:"To bank account",
    withdrawAmount:"Withdraw amount",
    yourRequestProcessed:"Your request is being processed",
    inProgress:"In progress",
    failed:"Failed",
    completed:"Completed",
    withdrawHistory:"Withdraw history",
    cancel:"Cancel",
    apply:"Apply",
    minimum:"minimum",
    pleaseContact:"Please contact us for more information",
    to:"to",

}
export default earn