const login = {
    emailPlacehold: 'Email',
    passwordPlacehold: 'password',
    login: 'Login',
    forgetPassword: 'Forget password',
    notMember: "Don't have an account?",
    clickToRegister: 'Sign up',
    or: 'or',
    socialAccount: 'Login with social accounts',
    pleaseAccount: 'Email is missing',
    pleasePassword: 'Password is missing',

    resetPassword:"Reset your passward",
    resetPasswordDes:"Please enter and confirm your new password bellow to access your account"

}
export default login