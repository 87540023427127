import $ from 'jquery'
import {
    dealResult
} from './dealResult'
// import Headers from './headers';
import {
    message
} from 'antd'

function ajaxRequest(methods, url, params, props) {
    return new Promise((resolve, reject) => {

        const token = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        $.ajax({
            type: methods,
            url: url,
            data: params,
            dataType: 'json',
            // contentType: 'application/json; charset=utf-8',
            // headers: headers,
            beforeSend: function (request) {
                // request.setRequestHeader('Authorization', token)

            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            // withCredentials: true,
            success: resp => {
                let flog = dealResult(resp, props)
                if (!flog) {
                    message.error(resp.message)
                    return
                }
                resolve(resp)
            },
            error: err => {
                console.log('errcc', err)
                reject(err)
            }
        })
    })
}

function ajaxRequestUpload(methods, url, params, props) {
    return new Promise((resolve, reject) => {
        var formData = new FormData()
        console.log('params',params)
        var fileType = params.file.type.split('/')[0]
        formData.append('file', params.file)
        formData.append('type',fileType)
        $.ajax({
            type: methods,
            url: url,
            processData: false,
            data: formData,
            // dataType: 'json',
            contentType: false,
            success: resp => {
                let flog = dealResult(resp, props)
                if (!flog) {
                    message.error(resp.msg)
                    return
                }
                resolve(resp)
            },
            error: err => {
                console.log('errcc', err)
                reject(err)
            }
        })
    })
}

export default {
    post(url, params, props) {
        return ajaxRequest('POST', url, params, props)
    },
    get(url, params, props) {
        return ajaxRequest('GET', url, params, props)
    },
    upload(url, params, props) {
        return ajaxRequestUpload('POST', url, params, props)
    }

}