const campaigns = {
    advanceSearch: 'Advance Search',
    duration: 'Duration',
    regionCoverage: 'Region coverage',
    AverageEarn: 'Average earnings',
    like: 'Like',
    joinNow: 'Join now',
    joined:"Go to task",
    comingSoon: 'Coming soon ',
    expired: 'Expired',

    


}

export default campaigns