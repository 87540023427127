const register = {
    yourInvitecode: 'Invitation Code',
    yourEmail: 'Email',
    passwordPlacehold: 'Password',
    passwordPlease: 'Password is missing',
    passwordRegx: 'Your password must be between 8-16 charactors long and include at least one uppercase letter(A-z) or one lower caseletter(a-z)',
    passwordConfirmPlacehold: 'Confirm password',
    passwordConfirmPlease: 'Confirm password is missing',
    passwordConfirmRegx: 'Passwords do not match.',
    // countryUpText: 'follow distribution area? (up to 5)',
    countryPlease: 'Country/Region',
    register: 'Register',
    agreeTo: 'By signing up your agree to our',
    termsAndConditions: 'Terms and Conditions',
    follower: "Follower's top location (up to 5)",
    submit:'Sign up'

}
export default register