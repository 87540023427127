import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {message, ConfigProvider} from 'antd';
import {getAntdLocal} from './locales/util.js';
import Layout from './components/Layout'

import {changeLanguage} from './modules/appReducers'
import * as AppActions from './actions/AppActions';
import AsyncLoadable from './utils/AsyncLoadable';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './style/base.css'
import './style/App.css'
import 'animate.css'
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import frFR from 'antd/es/locale/fr_FR';
import esES from 'antd/es/locale/es_ES';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('en');

//公共模块
const DefaultLayout = AsyncLoadable(() => import('./containers'))

//基础页面
const Login = AsyncLoadable(() => import('./views/Login'))
const Invitecode = AsyncLoadable(() => import('./views/Invitecode'))
const Home = AsyncLoadable(() => import('./views/home'))
const Register = AsyncLoadable(() => import('./views/Register'))
const Email = AsyncLoadable(() => import('./views/Email'))
const RegisterOther = AsyncLoadable(() => import('./views/RegisterOther'))
const Landing = AsyncLoadable(() => import('./views/Landing'))
const View404 = AsyncLoadable(() => import('./views/Others/404'))
const ModifyPassword = AsyncLoadable(() => import('./views/ModifyPassword'))
const Vip = AsyncLoadable(() => import('./views/Vip'))
const MyProfileMobile = AsyncLoadable(() => import('./views/MyProfileMobile'))
const MyProfileInfoMobile = AsyncLoadable(() => import('./views/MyProfileInfoMobile'))
const ChangeLanguage = AsyncLoadable(() => import('./views/ChangeLanguage'))
const ChangeGender = AsyncLoadable(() => import('./views/ChangeGender'))
const ChangePassword = AsyncLoadable(() => import('./views/ChangePassword'))
const ChangeName = AsyncLoadable(() => import('./views/ChangeName'))
const TermConditionPdf = AsyncLoadable(() => import('./views/TermConditionPdf'))
const ChangeSocial = AsyncLoadable(() => import('./views/ChangeSocial'))
const MyAddressMobile = AsyncLoadable(() => import('./views/MyAddressMobile'))
const AddAddressMobile = AsyncLoadable(() => import('./views/AddAddressMobile'))
const BankAccountMobile = AsyncLoadable(() => import('./views/BankAccountMobile'))
const AddAccountMobile = AsyncLoadable(() => import('./views/AddAccountMobile'))


const chooseLocale = (locale) => {
   console.log('localllll',locale) 
   switch(locale) {
     case 'en_US':
       return enUS;
     case 'zh_CN':
       return zhCN;
     case 'fr_FR':
       return frFR;
     case 'es_ES':
       return esES;  
     default:
       return enUS;    
   }
     
 
}


const App = (props) => {
  const {stores} = props;
  let locale = stores.appReducers.language;
  // console.log('getAntdLocal(locale)',getAntdLocal(locale))
  console.log('当前的语言是', locale)

  useEffect(() => {
 
  },[])
  
  return (
     <ConfigProvider locale={chooseLocale(locale)}>
        <Layout language={locale} >
            <Router>
             <Switch>
               <Route path='/' exact render={() => <Redirect to='/landing' />} />{' '}
               <Route path='/login' component={Login} />{' '}
               <Route path='/invitecode' component={Invitecode} />
               <Route path='/home' component={Home} /> 
               <Route path='/register' component={Register} />
               <Route path='/registerOther' component={RegisterOther} />
               <Route path='/email' component={Email} />
               <Route path='/landing' component={Landing} />
               <Route path='/modify-password' component={ModifyPassword} />
               <Route path="/vip" component={Vip}  />
               <Route path='/404' component={View404} />
               <Route path='/my_profile_m' component={MyProfileMobile} />
               <Route path='/my_profile_info_m' component={MyProfileInfoMobile} />
               <Route path='/change_language_m' component={ChangeLanguage} />
               <Route path='/change_gender_m' component={ChangeGender}/>
               <Route path='/change_password_m' component={ChangePassword}   />
               <Route path='/change_name_m' component={ChangeName} />
               <Route path='/term_condition_pdf' component={TermConditionPdf} />
               <Route path='/change_social_m/:id' component={ChangeSocial} />
               <Route path='/my_address_m' component={MyAddressMobile} />
               <Route path='/add_address_m' component={AddAddressMobile} />
               <Route path='/bank_account_m' component={BankAccountMobile} />
               <Route path='/add_account_m' component={AddAccountMobile} />
               <Route component={DefaultLayout} /> 
             </Switch> 
            </Router>
        </Layout> 
     </ConfigProvider>

  )
}

const mapStateToProps = (state) => ({
   stores: state,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLanguage,
      AppActions
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
