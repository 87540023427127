
const campaigns = {
    advanceSearch: 'Recherche avancée',
    searchInputPlaceholder: 'Recherche',
    duration: "Durée",
    regionCoverage:"Région concernée",
    distributionCountry: 'Couverture de la campagne par région',
    AverageEarn: 'Revenu moyen',
    like: "J'aime",
    joinNow: 'Rejoindre',
    joined:"Aller à la mission",
    comingSoon: 'Sera lancée ',
    expired: 'Terminé',
    
    



}

export default campaigns

