import Cookies from 'js-cookie'
let userInfo = Cookies.get('dsb_user_info')



export const getYearMonthDay = (nS) => {
    nS = nS?nS:''
    let rens = nS.replace(' ','T')
    let timeStamp = Date.parse(rens) 
    // return new Date(timeStamp).getFullYear()
    return (new Date(timeStamp).getDate()<10? '0'+ new Date(timeStamp).getDate(): new Date(timeStamp).getDate() ) + '.' + (new Date(timeStamp).getMonth() <9 ?  '0' + (new Date(timeStamp).getMonth() + 1) :  new Date(timeStamp).getMonth() + 1 )  + '.' + new Date(timeStamp).getFullYear()
    // return new Date(timeStamp).getFullYear() + '/' + (new Date(timeStamp).getMonth() + 1) + '/' + (new Date(timeStamp).getDate())
}

export const getYearMonthDayTime = (timeStamp) => {
    let date = new Date(timeStamp)
    let YY = new Date(timeStamp).getFullYear()
    let MM = new Date(timeStamp).getMonth() < 9 ? '0'+ (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1
    let DD = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate()
    let hh = new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours()
    let mm = new Date(timeStamp).getMinutes() < 10 ? '0' + new Date(timeStamp).getMinutes() : new Date(timeStamp).getMinutes()
    let ss = new Date(timeStamp).getSeconds() < 10 ? '0' + new Date(timeStamp).getSeconds() : new Date(timeStamp).getSeconds()
    return  `${DD}.${MM}.${YY} ${hh}:${mm}:${ss}`
}

export const isLogin = () => {
    if(userInfo){
        return true
    }
    return false      
}

export const getTransCard = (str) => {
    var reg = /^(\d{2})\d+(\d{4})$/
    str = str.replace(reg,"$1 ********** $2")
    return str
}

export const isMobile = () => {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if(!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)){
      return false
    }else{
      return true
    }
}