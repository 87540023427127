const campaigns = {
    advanceSearch: 'Búsqueda avanzada',
    duration: "Duración",
    searchInputPlaceholder: 'Búsqueda',
    regionCoverage: 'Cobertura regional',
    distributionCountry: 'Cobertura de región(es) de campaña',
    AverageEarn: 'Ganancia media',
    like: 'Favorito',
    joinNow: 'Únase Ahora',
    joined:"Ir a la tarea",
    comingSoon: 'Será lanzado',
    expired: 'finalizó',


}

export default campaigns