const address = {
    shippingAddress: "Adresse",
    delete: "Effacer",
    edit:"Modifier",
    newAddress:"Nouvelle adresse",
    addAddress:"Ajouter une adresse",
    name:"Prénom et nom",
    contactNum:"Numéro de téléphone",
    address:"Adress",
    country:"Pays/région",
    postCode:"Code postal",
    save:"Enregistrer",

}

export default address