import localesData from './index';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import en_US from 'antd/lib/locale-provider/en_US';
import th_TH from 'antd/lib/locale-provider/th_TH';
import es_ES from 'antd/lib/locale-provider/es_ES';

const defaultLanguage = 'en_US'; // window.navigator.language&&window.navigator.language.replace('-','_') || 'en_US';
const defaultLocal = localesData[defaultLanguage];
const langugaeKey = 'babelLang'

// 'zh_CN'、'en_US'、'id_ID'、'th_TH'
console.info(localesData);

export function getMessages(messages, prefix) {
  let output = {};
  for (const id in messages) {
    const key = prefix ? `${prefix}.${id}` : id;
    const message = messages[id];
    output[key] = message;
  }
  return output;
}

export function getCurrentLanguage() {
  let l = window.localStorage.getItem(langugaeKey);
  // if (l !== 'en_US' && l !== 'zh_CN') {
  //   l = defaultLanguage;
  // }
  if (!l) {
    l = defaultLanguage;
  }
  // setCurrentLanguage(l);
  return l;
}

export function setCurrentLanguage(val) {
  window.localStorage.setItem(langugaeKey, val || defaultLanguage);
}

export function chooseLocale(val) {
  let _val = val || getCurrentLanguage();
  switch (_val) {
    case 'en_US':
      return localesData.en_US;
    case 'es_ES':
      return localesData.es_ES;  
    // case 'zh_CN':
    //   return localesData.zh_CN;
    case 'fr_FR':
      return localesData.fr_FR;
    default:
      return defaultLocal;
  }
}

export function getCurrentLocalesData(id) {
  return chooseLocale(id);
}

export function getValue(id, locale) {
  return chooseLocale(locale)[id] || '';
  // return chooseLocale(getCurrentLanguage())[id] || '';
}

export function $t(id) {
  return getValue(id)
}

export function getAntdLocal(val) {
  if (val === 'en_US') {
    return en_US;
  } else if (val == 'es_ES' ) {
     return es_ES
  }else if (val === 'zh_CN') {
    return zh_CN;
  } else {
    return en_US;
  }
}