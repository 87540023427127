import TaskDetail from "../../views/TaskDetail/TaskDetail"

const taskDetail = {
    statusFur: 'Status further explanation , if needed',
    step1: 'Step1',
    stepDes1: 'Apply and receive your exclusive discount code',
    applyPromoCode: 'Apply promo code',
    howToUse: 'How to use promotion code efficient',
    copyCode: 'Copy',
    step2: 'Step2',
    stepDes2: 'Share on Social media',
    step3: 'Step3',
    stepDes3: 'Upload screenshot as edvidence',
    newImage: 'New image',
    inputWebsitelink: 'Upload sharing links as evidence',
    addUri: 'Add more links',
    submit: 'Submit',



    endDate: 'Close Date',
    discountCode: 'Discount code',

    clicktoApply: 'Promo code',
    noCode: 'New code due',

    step1: "Apply and receive your exclusive discount code",
    step2: "Share on Social media",
    step3: "Upload screenshots and links of your posts for this campaign",

    newImages: "New image",

    upPic:"Up to 5 images,supported formats: JPG, JPEG, PNG (Max. 3MB)",

    upLink: "Up to 3 links",

    addMoreLink: "Add more links",

    submit: "Submit",


    submitScreenshot:"Submit a screenshot of your social media post",
    submitLinkL:"Submit a link of your social media post",
    

    sampleStepDes:"Apply and receive the sample",
    applySample:"Click to apply",

}

export default taskDetail