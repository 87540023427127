const task = {
    searchInput: 'search in All tasks',
    taskState0: 'Task close',
    // taskState1: 'Pending activation',
    taskState1: 'New Task',
    taskState2: 'Pending proof upload',
    taskState3: 'Pending for review',
    taskState4: 'Pending new proof',
    taskState5: 'Proof verified',
    all: "All my tasks",
    status:"Task status",


    applicationDate: 'Application date',

    activated:"Activated",
    submitted:"Submitted",
    verified:"Verified",

}

export default task